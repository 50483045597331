body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Mairon";
  src: local("Mairon"), url(./fonts/Mairon.otf) format("opentype");
}
